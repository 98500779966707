import Vue from "vue";
import moment from "moment";
import i18n from "@/Locale";
import Enums from "@/Enums";

export const ChartUiLogic = {
  IntervalManager: {
    nextInterval(interval, currentDate) {
      var newDate = null;
      if (interval == Enums.ChartInterval.Day.Value) {
        newDate = currentDate.add(1, "days");
      } else if (interval == Enums.ChartInterval.Week.Value) {
        newDate = currentDate.add(1, "weeks");
      } else if (interval == Enums.ChartInterval.Month.Value) {
        newDate = currentDate.add(1, "months");
      } else if (interval == Enums.ChartInterval.Year.Value) {
        newDate = currentDate.add(1, "years");
      }
      return newDate;
    },
    previousInterval(interval, currentDate) {
      var newDate = null;
      if (interval == Enums.ChartInterval.Day.Value) {
        newDate = currentDate.subtract(1, "days");
      } else if (interval == Enums.ChartInterval.Week.Value) {
        newDate = currentDate.subtract(1, "weeks");
      } else if (interval == Enums.ChartInterval.Month.Value) {
        newDate = currentDate.subtract(1, "months");
      } else if (interval == Enums.ChartInterval.Year.Value) {
        newDate = currentDate.subtract(1, "years");
      }
      return newDate;
    },
    today() {
      return moment();
    },
    setInterval(interval, xAxis, currentDate) {
      var currentDateString = null;
      if (interval == Enums.ChartInterval.Day.Value) {
        xAxis = {
          ...xAxis,
          minimum: moment(currentDate)
            .startOf("days")
            .subtract(1, "hours")
            .format(),
          maximum: moment(currentDate)
            .add(1, "days")
            .startOf("days")
            .add(1, "hours")
            .format(),
        };
        currentDateString = moment(currentDate).format("LL");
        Vue.set(xAxis, "intervalType", "Hours");
        Vue.set(xAxis, "interval", 2);
        Vue.set(xAxis, "labelFormat", "HH:mm");
      } else if (interval == Enums.ChartInterval.Week.Value) {
        xAxis = {
          ...xAxis,
          minimum: moment(currentDate)
            .day(1)
            .startOf("days")
            .subtract(1, "days")
            .format(),
          maximum: moment(currentDate)
            .day(7)
            .startOf("days")
            .add(1, "days")
            .format(),
        };
        currentDateString =
          moment(currentDate).format("YYYY. MMMM") +
          ", " +
          moment(currentDate).weeks() +
          ". " +
          i18n.t("base.calendar.week");
        Vue.set(xAxis, "intervalType", "Days");
        Vue.set(xAxis, "interval", 1);
        Vue.set(xAxis, "labelFormat", "MM-dd");
      } else if (interval == Enums.ChartInterval.Month.Value) {
        xAxis = {
          ...xAxis,
          minimum: moment(currentDate)
            .startOf("months")
            .startOf("days")
            .subtract(1, "days")
            .format(),
          maximum: moment(currentDate)
            .endOf("months")
            .startOf("days")
            .add(1, "days")
            .format(),
        };
        currentDateString = moment(currentDate).format("YYYY. MMMM");
        Vue.set(xAxis, "intervalType", "Days");
        Vue.set(xAxis, "interval", 3);
        Vue.set(xAxis, "labelFormat", "MM-dd");
      } else if (interval == Enums.ChartInterval.Year.Value) {
        xAxis = {
          ...xAxis,
          minimum: moment(currentDate)
            .startOf("years")
            .startOf("days")
            .subtract(1, "days")
            .format(),
          maximum: moment(currentDate)
            .endOf("years")
            .add(1, "days")
            .startOf("days")
            .add(1, "days")
            .format(),
        };
        Vue.set(xAxis, "intervalType", "Months");
        Vue.set(xAxis, "interval", 1);
        Vue.set(xAxis, "labelFormat", "MM-dd");
        currentDateString = moment(currentDate).format("YYYY");
      }
      Vue.set(xAxis, "plotOffsetRight", 10);
      Vue.set(xAxis, "plotOffsetLeft", 10);
      return {
        interval: interval,
        xAxis: xAxis,
        currentDate: currentDate,
        currentDateString: currentDateString,
      };
    },
    setIntervalToParams(interval, currentDate) {
      var firstDay = moment(currentDate).startOf("days");
      var lastDay = moment(currentDate).startOf("days");
      if (interval == Enums.ChartInterval.Day.Value) {
        lastDay = lastDay.endOf("days");
      } else if (interval == Enums.ChartInterval.Week.Value) {
        firstDay = firstDay.day(1);
        lastDay = lastDay.day(7).endOf("days");
      } else if (interval == Enums.ChartInterval.Month.Value) {
        firstDay = firstDay.startOf("month");
        lastDay = lastDay.endOf("month").endOf("days");
      } else if (interval == Enums.ChartInterval.Year.Value) {
        firstDay = firstDay.startOf("years");
        lastDay = lastDay.endOf("years").endOf("days");
      }
      return [firstDay, lastDay];
    },
    getIntervalParamsToDiary(interval, currentDate) {
      const firstLastDateArray = this.setIntervalToParams(
        interval,
        currentDate
      );
      return {
        "MeasuredAt-from": firstLastDateArray[0].format(),
        "MeasuredAt-to": firstLastDateArray[1].format(),
        "-orderby": "MeasuredAt",
      };
    },
    getIntervalParamsToChart(interval, currentDate) {
      const firstLastDateArray = this.setIntervalToParams(
        interval,
        currentDate
      );
      return {
        From: firstLastDateArray[0].format("YYYY-MM-DD"),
        To: firstLastDateArray[1].format("YYYY-MM-DD"),
        "-orderby": "Date",
      };
    },
  },
  getChartCssClassBySize(chartSize) {
    switch (chartSize) {
      case Enums.ChartSize.SMALL:
        return "chart-small";
      case Enums.ChartSize.MEDIUM:
        return "chart-medium";
      case Enums.ChartSize.LARGE:
        return "chart-large";
    }
    return "chart-medium";
  },
};
